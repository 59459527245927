import styled from "styled-components";
import { mediaQueriesRanged } from "./breakpoints";

/**
 * GridTemplate
 * Use this whenever a page needs to follow grid guidelines
 */
export const GridTemplate = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
  // anything smaller than 350
  grid-template-columns: repeat(1, 1fr);
  column-gap: 1.2rem;
  padding: 2rem;
  padding-left:
  padding-bottom: 0rem;
  @media ${mediaQueriesRanged.sm} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.2rem;
    // padding: 2rem;
  }
  @media ${mediaQueriesRanged.md} {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3.2rem;
    // padding: 3.2rem;
  }
  @media ${mediaQueriesRanged.lg} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 3.2rem;
    // padding: 4.8rem;
  }
  @media ${mediaQueriesRanged.xl} {
    grid-template-columns: repeat(12, minmax(0, 10.4rem));
    column-gap: 3.2rem;
    margin: auto;
    max-width: 160rem;
    /* padding: 6.4rem; */
  }
`;

export const RecipeGrid = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;

  // xs
  grid-template-columns: repeat(1, 1fr);
  padding-top: 2rem;
  padding-bottom: 0rem;

  @media ${mediaQueriesRanged.sm} {
    grid-template-columns:
      [page-start] 20px
      repeat(2, [col-start] minmax(0, 1fr) [col-end] 20px)
      [page-end];
  }
  @media ${mediaQueriesRanged.md} {
    grid-template-columns:
      [page-start] 30px
      repeat(4, [col-start] minmax(0, 1fr) [col-end] 30px)
      [page-end];
  }
  @media ${mediaQueriesRanged.lg} {
    grid-template-columns:
      [page-start] 60px
      repeat(7, [col-start] minmax(0, 1fr) [col-end] 40px)
      [col-start] minmax(0, 1fr) [col-end] 60px
      [page-end];
    grid-template-rows: auto auto 1fr;
  }
  @media ${mediaQueriesRanged.xl} {
    grid-template-columns:
      [page-start] minmax(0, 1fr)
      repeat(7, [col-start] 110px [col-end] 40px)
      [col-start] 110px
      [col-end] minmax(0, 1fr)
      [page-end];

    margin: auto;
    max-width: 160rem;
  }
`;
