import Lottie from "react-lottie";
import styled from "styled-components"
// import animationData from '~/lottie/404/lottieflow-404-12-9-000000-easey.json';
import animationData from '~/lottie/404/lottieflow-404-12-6-000000-easey.json';
import { RecipeGrid } from "~/styles/grid";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};


export default function Custom404() {
  return (
    <RecipeGrid>
      <Container>
        <Elements>
          <Lottie options={lottieOptions}/>
        </Elements>
      </Container>
    </RecipeGrid>
  )
}

const Container = styled.div`
  grid-column: page-start/page-end;

  width: 100%;
  // height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Elements = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`
